<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="mb-4 d-flex align-center">
          <span class="title font-weight-bold" label>1.2</span>
          <v-icon class="mx-2">mdi-circle-small</v-icon>
          <div class="subtitle-2">Information requirement list from client</div>
        </div>
        <div class="mb-4 d-flex align-center">
          <v-btn color="primary" elevation="0" small @click="addpbcdialog=true">Add New</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" small @click="addpbcdialog=true">Confirm & Send Mail</v-btn>
        </div>

        <!-- PBC Not added for custom scope -->
        <div class="my-8">
          <div class="subtitle-1">
            <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
            PBC not added for the following custom scope
          </div>
          <div class="ml-5 px-2 py-1 hoverable d-flex align-center">
            <v-icon class="mr-2">mdi-circle-medium</v-icon>
            <span>Procedure Name 1</span> 
            <v-chip label small class="ml-2 caption d-flex align-center" outlined>
              <span>Area / Sub Area</span>
            </v-chip>
            <v-btn color="primary" class="ml-2" small>Add PBC</v-btn>
            <v-btn color="primary" class="ml-2" small @click="mappbcdialog=true">Map PBC</v-btn>
            <v-btn color="warning" text class="ml-2" small >Ignore PBC</v-btn>
          </div> 
          <div class="ml-5 px-2 py-1 hoverable d-flex align-center">
            <v-icon class="mr-2">mdi-circle-medium</v-icon>
            <span>Procedure Name 2</span> 
            <v-chip label small class="ml-2 caption d-flex align-center" outlined>
              <span>Area / Sub Area</span>
            </v-chip>
            <v-btn color="primary" class="ml-2" small>Add PBC</v-btn>
            <v-btn color="primary" class="ml-2" small @click="mappbcdialog=true">Map PBC</v-btn>
            <v-btn color="warning" text class="ml-2" small >Ignore PBC</v-btn>
          </div> 
        </div> 


        <div class="mb-4 d-flex align-center">
          <v-icon class="mr-2" color="success">mdi-email-check</v-icon>
          Mail sent
          <v-icon class="ml-6 mr-2" color="error">mdi-email-remove</v-icon>
          Mail not sent yet
        </div>

        <v-dialog v-model="addpbcdialog" max-width="600">
          <v-card :loading="addpbcloading" :disabled="addpbcloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span v-if="addpbcdata._id" class="subtitle-1 white--text">Update PBC</span>
              <span v-else class="subtitle-1 white--text">Add New PBC</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="addpbcdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown :items="[{'value': '2', 'name': 'Level 2'}, {'value': '3', 'name': 'Level 3'}]" itemtext="name" itemvalue="value" v-model="addpbcdata.level" label="Level" :error="addpbcerror.level"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addpbcdata.description" label="Description" :error="addpbcerror.description"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-dropdown v-model="addpbcdata.area" :items="['Procure to Pay']" label="Scope Area" :error="addpbcerror.verification"/>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="addpbcdata.verification" label="Procedures" :error="addpbcerror.verification" hidedetails/>
                </v-col>
                <v-col cols="12" class="mt-0 py-1 mb-4">
                  <div class="font-weight-bold subtitle-2 mt-2">Selected Procedures</div>
                  <div class="d-flex hoverable px-1">
                    <strong class="mr-2">1.</strong>
                    <span> Custom Procedure 1</span>
                    <v-spacer></v-spacer>
                    <v-btn icon small class="ml-2"><v-icon>mdi-close</v-icon></v-btn>                    
                  </div>
                  <div class="d-flex hoverable px-1">
                    <strong class="mr-2">2.</strong>
                    <span> Custom Procedure 2</span>
                    <v-spacer></v-spacer>
                    <v-btn icon small class="ml-2"><v-icon>mdi-close</v-icon></v-btn>                    
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn v-if="addpbcdata._id" small color="primary" @click="editPBC()">Update PBC</v-btn>
              <v-btn v-else small color="primary" @click="addPBC()">Add PBC</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="mappbcdialog" max-width="600">
          <v-card :loading="mappbcloading" :disabled="mappbcloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Map Existing PBC to a Custom Scope</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="mappbcdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="pt-4">
              <div class="subtitle-1 mb-4"><strong>Procedure: </strong>Custom Scope Name</div>
              <div class="font-weight-bold subtitle-2 mb-1">Existing PBC List</div>
              <div class="d-flex hoverable px-1 align-center cursor-pointer" v-ripple v-for="(v, k) in computedfilelist" :key="k">
                <v-checkbox hide-details class="mt-0" dense></v-checkbox>
                <span>{{v.name || v.description}}</span>
              </div>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary">Save Mapping</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="responsedialog" max-width="600">
          <v-card :loading="addpbcloading" :disabled="addpbcloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Add Information</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="responsedialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <div class="font-weight-bold subtitle-1 mb-4">{{uploadfiledata.name || uploadfiledata.description}}</div>
              <div v-if="uploadfiledata.__level === '1'">
                <div class="mb-1">Required columns</div>
                <div class="mb-4">
                  <v-chip class="mb-1 mr-1" small label v-for="(v,k) in ((uploadfiledata.__file_config || {}).columnmap || [])" :key="k">{{v[1] || ''}}</v-chip>
                </div>
              </div>
              <lb-file v-if="uploadfiledata.__level === '1' || uploadfiledata.level === '2'" v-model="file" :label="(uploadfiledata.upload_detail || {}).originalname || ((level1setup[uploadfiledata._id] || {}).upload_detail || {}).originalname || ''"/>
              <lb-textarea v-model="level3response" label="Response" :error="addpbcerror.response"/>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="editPBC('response')">Update PBC</v-btn>
              
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="uploadfiledialog" max-width="600">
          <v-card :loading="uploadfileloading" :disabled="uploadfileloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="uploadfiledialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <div class="font-weight-bold subtitle-1 mb-4">{{uploadfiledata.name}}</div>
              <div class="mb-1">Required columns</div>
              <div class="mb-4">
                <v-chip class="mb-1 mr-1" small label v-for="(v,k) in ((uploadfiledata.__file_config || {}).columnmap || [])" :key="k">{{v[1] || ''}}</v-chip>
              </div>
              <lb-file v-model="file" label=""/>
              <div v-if="checkcolumnerror">
                <div class="mb-1 error--text"><strong>Error: </strong>{{checkcolumnerror}}</div>
                <div class="mb-4">
                  <v-chip class="mb-1 mr-1" small label v-for="(v,k) in (checkcolumnlist || [])" :key="k">{{v || ''}}</v-chip>
                </div>
              </div>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn v-if="uploadfiledata.__level === '1'" small color="primary" @click="uploadL1File()">Upload</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="uploadfilel2dialog" max-width="600">
          <v-card :loading="uploadfileloading" :disabled="uploadfileloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="uploadfilel2dialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4">
              <div class="font-weight-bold subtitle-1 mb-4">{{uploadfiledata.description}}</div>
              <lb-file v-model="file" label=""/>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn v-if="this.file" small color="primary" @click="uploadFile()">Upload</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deletedialog" max-width="600">
          <v-card :loading="deleteloading" :disabled="deleteloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              This will delete <b>{{deletedata.description}}</b> level {{deletedata.level}} requirement from PBC.
              <br/>
              Do you want to proceed?
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small @click="deletedialog = false" text>Cancel</v-btn>
              <v-btn small color="primary" @click="deletePBC(deletedata._id)">Confirm</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-simple-table dense class="custom-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="#">#</th>
                <th scope="description">Description</th>
                <th scope="level">Level</th>
                <th scope="status">Status</th>
                <th scope="file-response">File/Response</th>
                <th scope="information-owner">Information Owner</th>
                <th scope="last-updated">Last Updated</th>
                <th scope="action">Action</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(v, k) in computedfilelist" :key="k" class="hoverable py-2">
                  <td class="py-2">{{k+1}}</td>
                  <td v-if="v.__level === '1'">
                    {{(v || {}).name}}
                  </td>
                  <td v-else-if="v.level === '2' || v.level === '3'">
                    {{(v || {}).description}}
                  </td>
                  <td class="">
                    <v-avatar v-if="v.__level === '1'" class="white--text" size="25" color="error">1</v-avatar>
                    <v-avatar v-else-if="v.level === '2'" class="white--text" size="25" color="warning" >2</v-avatar>
                    <v-avatar v-else-if="v.level === '3'" class="white--text" size="25" color="info">3</v-avatar>
                  </td>
                  <td v-if="v.__level === '1'">
                    <v-chip label small color="error" v-if="((level1setup[v._id] || {}).process_detail || {}).stage === -1">Error</v-chip>
                    <v-chip label small color="success" v-else-if="((level1setup[v._id] || {}).process_detail || {}).stage === 1">Completed</v-chip>
                    <v-chip label small color="warning" v-else-if="((level1setup[v._id] || {}).process_detail || {}).stage === 0">Processing</v-chip>
                    <v-chip label small color="info" v-else>Pending</v-chip>
                  </td>
                  <td v-else-if="v.level === '2'">
                    <v-chip label small color="success" v-if="v.upload">Completed</v-chip>
                    <v-chip label small color="info" v-else>Pending</v-chip>
                  </td>
                  <td v-else-if="v.level === '3'">
                    <v-chip label small color="success" v-if="v.response">Completed</v-chip>
                    <v-chip label small color="info" v-else>Pending</v-chip>
                  </td>
                  <td class="pbc-response-holder">
                    <span v-if="v.level === '3'">{{v.response || ''}}</span>
                    <div v-if="v.level === '2' && v.upload">
                      <v-icon class="mr-1">mdi-file</v-icon>
                      <span>{{(v.upload_detail || {}).originalname}}</span>
                      <v-btn class="ml-2" v-if="v.upload" text icon small @click="$nova.downloadFile(v.upload)"> <v-icon>mdi-download</v-icon></v-btn>
                    </div>
                    <div v-if="v.__level === '1' && (level1setup[v._id] || {}).upload">
                      <span class="error--text" v-if="((level1setup[v._id] || {}).process_detail || {}).stage === -1">{{((level1setup[v._id] || {}).process_detail || {}).processerror}}<br/></span>
                      <v-icon class="mr-1">mdi-file</v-icon>
                      <span>{{((level1setup[v._id] || {}).upload_detail || {}).originalname}}</span>
                      <v-btn class="ml-2" v-if="(level1setup[v._id] || {}).upload" text icon small @click="$nova.downloadFile((level1setup[v._id] || {}).upload)"> <v-icon>mdi-download</v-icon></v-btn>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-center">
                      <v-icon class="mr-2" color="error">mdi-email-remove</v-icon>
                      <div>
                        <div>Owner Name</div>
                        <div>email@gmail.com</div>
                      </div>
                      <v-btn icon small class="ml-2"><v-icon>mdi-pencil</v-icon></v-btn>
                    </div>
                  </td>
                  <td>{{$nova.formatDate(new Date())}}</td>
                  <td class="">
                    <v-btn icon small @click="responsedialog=true;uploadfiledata=v;level3response=v.response||''"><v-icon>mdi-forum</v-icon></v-btn>
                    <v-btn icon v-if="(v.level === '2' || v.level === '3') && v._id" small class="" @click="addpbcdata=v;addpbcdialog=true"> <v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn icon v-if="(v.level === '2' || v.level === '3') && v._id" small class="" @click="deletedata=v;deletedialog=true"> <v-icon>mdi-delete</v-icon></v-btn>
                  </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
let xlsx = require('xlsx');

export default {
  name: 'assignment_setup_fileconfig',
  data: function(){
    return {
      loading: false,
      addpbcdialog: false,
      addpbcloading: false,
      addpbcdata: {},
      addpbcerror: {},
      filelist: [],
      error: "",
      file: null,
      uploadfiledialog: false,
      uploadfileloading: false,
      uploadfiledata: {},
      uploadfilel2dialog: false,
      level1setup: {},
      othersetup: [],
      level3response: '',
      level3itemdata: {},
      responsedialog: false,
      deletedialog: false,
      deleteloading: false,
      deletedata: {},
      mappbcdialog: false,
      mappbcloading: false,
      checkcolumnerror: "",
      checkcolumnlist: [],

      filedata: {},
      fileconfig: {},
      configuredFileIds: [],
      configfileid: "",
    }
  },
  components: {
   // pageHeader,managelist
  },
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = false;
      this.addpbcdialog = false;
      this.addpbcloading = false;
      this.addpbcdata = {};
      this.addpbcerror = {};
      this.filelist = [];
      this.error = "";
      this.file = null;
      this.uploadfiledialog = false;
      this.uploadfileloading = false;
      this.uploadfiledata = {};
      this.uploadfilel2dialog = false;
      this.level1setup = {};
      this.othersetup = [];
      this.level3response = '';
      this.level3itemdata = {};
      this.responsedialog = false;
      this.deletedialog = false;
      this.deleteloading = false;
      this.deletedata = {};
      this.checkcolumnerror = "";
      this.checkcolumnlist = [];

      this.fileconfig = {};
      this.configuredFileIds = [];
      this.configfileid = "";
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      let files = {};
      this.axios.post("/v2/ia/config/getfilelist").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let fileids = [];
          let data = dt.data.data; 
          for (const i of  data) {
            files[i._id] = i;
            fileids.push(i._id);
          }
          return this.axios.post("/v2/ia/config/getfileconfig", {data: fileids});
        }else throw new Error("No scope details found");
      }).then(dt=> {
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){
              for (const j of dt.data.data) {
                let ndt = files[j.file];
              ndt.__level = "1";
              ndt.__file_config = j;
              this.filelist.push(ndt);
              }
          }
          return this.axios.post("/v2/ia/assignment/getpbcsetup/"+this.id);
        }else throw new Error ("Error fething file config details");
      }).then(dt=> {
        if(dt.data.status === "success"){

          for (const k of dt.data.data) {
            if(k.level === "1") this.level1setup[k.file] = k;
            else this.othersetup.push(k);
          }
        }else throw new Error ("Error fetching pbc setup");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");

      }).finally(() => {
        this.loading = false;
      })
    },
    addPBC(){
      this.addpbcloading = true;
      this.addpbcerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/assignment/addpbc/"+this.id, {data: this.addpbcdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Added!");
          this.refreshData(false);
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addpbcerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addpbcloading = false;
      })
    },
    editPBC(type=""){
      this.addpbcloading = true;
      this.addpbcerror = {};
      Promise.resolve().then(() => {
        let data = {...this.addpbcdata};
        if(type === "response") data = {response: this.level3response, _id: this.addpbcdata._id};
        return this.axios.post("/v2/ia/assignment/editpbc/"+this.id, {data: data});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Updated!");
          this.refreshData(false);
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.addpbcerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.addpbcloading = false;
      })
    },
    deletePBC(did){
      this.deleteloading = true;
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/assignment/deletepbc/"+this.id, {data: {_id: did}});
      }).then(dt => {
        if(dt.data.status === "success" && (dt.data.deletedCount || 0) > 0){
          this.$store.commit("sbSuccess", "Deleted!");
          this.deletedialog = false;
          this.refreshData(false);
        }else {
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.deleteloading = false;
      })
    },
    confirmConfiguration(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/confirmfileconfig/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.refreshData();
        }else throw new Error (dt.data.message || "Error processing request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    uploadFile(type="l2"){
      if(type==="l2") this.uploadfileloading = true;
      let uploadFileFlag = true;
      let newuploaddata = {};
      Promise.resolve().then(() => {
        if(this.uploadfiledata.upload && !this.file) uploadFileFlag = false;
        if(uploadFileFlag) return this.$nova.uploadFile(this.axios, this.file);
      }).then(dt => {
        let proceedFlag = false;
        if(uploadFileFlag){
          if(dt.data.status === "success" && dt.data.data.length > 0){
            newuploaddata = {upload: dt.data.data[0]._id};
            if(type==="l1"){
              newuploaddata.file = (this.uploadfiledata.__file_config || {}).file || '';
              newuploaddata.description = this.uploadfiledata.name || '';
            }else newuploaddata._id = this.uploadfiledata._id;
      
            this.file.id = dt.data.data[0]._id;
            proceedFlag = true;
          }else throw new Error ("Unable to upload file");
        }else proceedFlag = true;
        let url = "/v2/ia/assignment/addl2file/"+this.id;
        if(type === "l1") url = "/v2/ia/assignment/addl1file/"+this.id;
        if(proceedFlag) return this.axios.post(url, {data: newuploaddata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "File Uploaded");
          this.refreshData();
        }else{
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        if(type==="l2") this.uploadfileloading = false;
      })
    },
    uploadL1File: function(){
      this.uploadfileloading = true;
      Promise.resolve().then(() => {
        if(this.file instanceof File) {
          if(['xls','xlsx','csv'].includes(this.file.name.split('.').pop())){
            if(['xls','xlsx'].includes(this.file.name.split('.').pop()) && this.file.size > (1024*1024*2)){
              throw new Error ("'xls' & 'xlsx' file size has to be less than 2 MB");
            }else{
              let cols = [];
              let fileconfig = (this.uploadfiledata.__file_config || {});
              (fileconfig.columnmap || []).forEach(el => {cols.push(el[1]);});
              this.checkUploadFileHeaders(this.file, cols, this.file.name.split('.').pop(), Number(fileconfig.ignorerows) || 0, Number(fileconfig.ignorecolumns) || 0).then((dt) => {
                if((dt.duplicates || []).length > 0){
                  this.checkcolumnerror = "Duplicates columns found";
                  this.checkcolumnlist = dt.duplicates;
                }else if((dt.missingcols || []).length > 0){
                  this.checkcolumnerror = "Following columns are missing";
                  this.checkcolumnlist = dt.missingcols;
                }else return this.uploadFile("l1");
                console.log(dt);
              });
            }
          }else throw new Error ("Invalid type");
        }else throw new Error ("No file found");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.uploadfileloading = false;
      })
    },
    checkUploadFileHeaders: function(f,c,fmt,t=0,l=0){
      return new Promise((resolve, reject) => {
        let readCSVFile = new FileReader();
        let headerFile = new FileReader();
        let hf = null;
        readCSVFile.onload = function(e) {
          let d = e.target.result.split('\n');
          if(d.length >= t+1){
            let hd = d.slice(Math.max(t,0));
            hd = hd.join("\n");
            hf = new File([hd], 'tfile', {type:'text/csv'});
            headerFile.readAsBinaryString(hf);
          }
          else{
            reject(Error("Invalid File"));
          }
        }
        readCSVFile.onerror = function(ex) {reject(ex)};
        headerFile.onload = function(e) {
          let d = e.target.result;
          let wb = xlsx.read(d, {type: 'binary', cellDates: true, raw: true}); 
          let rdt = [];
          let sh = wb.SheetNames[0];
          let dt = xlsx.utils.sheet_to_json(wb.Sheets[sh], {header: 1});
          if(fmt != "csv") dt = dt.splice(t,dt.length);
          let h = dt[0].slice(l,dt[0].length);
          let flag = true;
          let missingcols = [];
          h = h.map(x => {return x.toString().trim();})
          let uqlist = []
          let duplist = []
          h.forEach(x => { 
            if(uqlist.indexOf(x) === -1) uqlist.push(x);
            else duplist.push(x);
          });
          if(duplist.length > 0) return resolve({duplicates: duplist});
          
          for (let i = 0; i < c.length; i++) {
            let el = c[i].toString().trim();
            if(h.indexOf(el) === -1) {
              missingcols.push(c[i].toString());
              flag = false;
            }            
          }
          if(flag){
            rdt = dt;
            if(l > 0){
              rdt = rdt.map(el => {
                return el.slice(l,el.length);
              });
            }
          }
          resolve({data: rdt, missingcols: missingcols});
        }
        headerFile.onerror = function(ex) {reject(ex)};
        if(fmt == "csv") readCSVFile.readAsText(f.slice(0, 1024*1024*20));
        else headerFile.readAsBinaryString(f);
      });
    },
  },
  computed: {
    computedfilelist: function(){
      return [...this.filelist, ...this.othersetup];
    }
  },
}
</script>

<style scoped>
.pbc-response-holder{
  max-width: 350px !important;
}
.pbc-action-holder{
  min-width: 200px !important;
}
</style>
